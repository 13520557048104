import Vue from "vue";
import firebase from 'firebase/compat/app';

export default {
};

export const realtime = {
    install(Vue) {
        Vue.prototype.$realtime = {
            database: firebase.database(),
            timestamp: firebase.database.ServerValue.TIMESTAMP,
            checkLoginStatus: function (uid) {
                // Create a reference to this user's specific status node.
                // This is where we will store data about being online/offline.
                var userStatusDatabaseRef = firebase.database().ref('/pharmacy_user_status/' + uid);

                // We'll create two constants which we will write to
                // the Realtime database when this device is offline
                // or online.
                var isOfflineForDatabase = {
                    state: 'offline',
                    last_changed: firebase.database.ServerValue.TIMESTAMP,
                };
                return firebase.database().ref('.info/connected').on('value', function (snapshot) {
                    if (snapshot != null) {
                        if (snapshot.val() == false) {
                            return;
                        };
                    }
                    userStatusDatabaseRef.onDisconnect().update(isOfflineForDatabase);
                });
            },
        }
    }
};

Vue.use(realtime);