import Vue from "vue";

export default {
};

export const testMode = {
  install(Vue) {
    Vue.prototype.$testMode = {
      isTestMode: false,
      noPhoneAuth: false,
    }
  }
};

Vue.use(testMode);