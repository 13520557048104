/*
=========================================================
* Vuetify Soft UI Dashboard PRO - v1.0.0
=========================================================

* Product Page:  https://store.vuetifyjs.com/products/vuetify-soft-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import VueMoment from "vue-moment";
import "./plugins/firebase";
import firebase from "firebase/compat/app";
import theme from "./plugins/theme";
import utils from "./plugins/utils";
import rules from "./plugins/rules";
import database from "./plugins/database";
import auth from "./plugins/auth";
import storage from "./plugins/storage";
import models from "./plugins/models";
import functions from "./plugins/functions";
import gorela from "./plugins/gorela";
import testMode from "./plugins/testMode";
import realtime from "./plugins/realtime";
import VueYoutube from "vue-youtube";

Vue.config.productionTip = false;
// Vue.config.devtools = false;

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);

// plugin setup
Vue.use(VueMoment);
Vue.use(DashboardPlugin);
Vue.use(VueYoutube);

new Vue({
  router,
  vuetify,
  firebase,
  theme,
  utils,
  rules,
  database,
  auth,
  storage,
  models,
  functions,
  gorela,
  testMode,
  realtime,
  render: (h) => h(App),
}).$mount("#app");
