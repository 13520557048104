import Vue from "vue";

export default {
};

export const utils = {
  install(Vue) {
    Vue.prototype.$utils = {
      separateThousands: function (text) {
        var result = "";
        var newText = (text + '').replace(/,/g, "");
        for (var i = 1; i <= newText.length; i++) {
          result = newText.charAt(newText.length - i) + result;
          if (i % 3 == 0 && i != newText.length) {
            result = "," + result;
          }
        }
        return result;
      },
      toNumber: function (text) {
        let number = text * 1;
        return number;
      },
      toText: function (number) {
        let text = number + '';
        return text;
      },
      lpad: function (text, padString, length) { // 숫자 앞에 0 붙이기
        let str = text + '';
        while (str.length < length)
          str = padString + str;
        return str;
      },
      itemStyle(condition, name, color) {
        if (condition) {
          return name + ':' + color;
        }
        else {
          return '';
        }
      },
      textValue(text) {
        if (text != null) return text;
        else return "";
      },
      numberValue(number) {
        if (number != null) return number;
        else return 0;
      },
      getShortened(text, maxLength) {
        let result = text + '';
        if (text.length > maxLength) {
          result = text.slice(0, maxLength) + '..';
        }
        return result;
      },
      isEmpty(text) {
        if ((text == undefined) || (typeof text == "undefined") || (text == null) || (text.length == 0)) {
          return true;
        }
        else {
          return false;
        }
      },
      isSameDate(date1, date2) {
        if (Number(date1) == Number(date2)) {
          return true;
        }
        else {
          return false;
        }
      },
      getPhoneText: function (text) {
        var result = "";
        var newText = (text + '').replace(/-/g, "");
        newText = newText.replace(/ /g, "");
        for (var i = 1; i <= newText.length; i++) {
          result = newText.charAt(newText.length - i) + result;
          if (i % 4 == 0 && i != newText.length) {
            result = "-" + result;
          }
        }
        return result;
      },
      onInput(text, object, regExp, maxLength, nextFocus, onMessage) {
        var result = text + '';
        if (regExp != null) {
          var matched = result.match(regExp);
          if (matched != null) {
            let matchedText = '';
            for (let i = 0; i < matched.length; i++) {
              matchedText += matched[i];
            }
            result = matchedText;
          }
          else {
            result = '';
          }
          if (result.length != text.length) {
            if (onMessage != null) {
              onMessage(text);
            }
          }
        }
        if (maxLength != null) {
          result = result.substring(0, maxLength);
        }
        object.lazyValue = result;
        if (nextFocus != null) {
          if (result.length == maxLength) {
            nextFocus.focus();
          }
        }
        return result;
      },
      randomValue(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      },
      createPhoneAuthCode() {
        return this.randomValue(100000, 999999) + '';
      },
      getTelPhoneText: function (text) {
        let phoneNumber = text + '';
        let phone1 = '';
        let phone2 = '';
        let phone3 = '';
        if (phoneNumber.startsWith('02')) {
          // phone1: 앞 2자리, phone3: 뒤 4자리, phone2: 나머지
          phone1 = phoneNumber.slice(0, 2);
          phone2 = phoneNumber.slice(2, phoneNumber.length - 4);
          phone3 = phoneNumber.slice(phoneNumber.length - 4);
        } else {
          let areaCodes = ['031', '032', '033', '041', '042', '043', '044', '051', '052', '053', '054', '055', '061', '062', '063', '064'];
          let areaCode = phoneNumber.slice(0, 3);
          if (areaCodes.includes(areaCode)) {
            // phone1: 앞 3자리, phone3: 뒤 4자리, phone2: 나머지
            phone1 = phoneNumber.slice(0, 3);
            phone2 = phoneNumber.slice(3, phoneNumber.length - 4);
            phone3 = phoneNumber.slice(phoneNumber.length - 4);
          } else {
            // 4자리씩 구분
            let phoneNumbers = this.getPhoneText(phoneNumber).split('-');
            if (phoneNumbers.length > 2) {
              phone1 = phoneNumbers[0];
              phone2 = phoneNumbers[1];
              phone3 = phoneNumbers[2];
            }
          }
        }
        return phone1 + "-" + phone2 + "-" + phone3;
      },
      deliveryStateToText(code) {
        const orderStateMap = {
          "OrderState.order": "주문등록",
          "OrderState.ready": "발송준비중",
          "OrderState.delivery": "배송중",
          "OrderState.deliveryPartial": "(부분)배송중",
          "OrderState.deliveryDone": "수령완료",
          "OrderState.cancel": "취소",
          "OrderState.returnRequest": "반품요청",
          "OrderState.return": "반품완료",
        };

        if (
          this.isEmpty(code) === false &&
          this.isEmpty(orderStateMap[code]) === false
        ) {
          return orderStateMap[code];
        } else {
          return "기타";
        }
      },
    };
  },
};

Vue.use(utils);