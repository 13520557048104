<template>
<v-app-bar
    :color="background"
    height="auto"
    class="mt-5 px-0 mx-6 border-radius-xl toolbar-content-padding-y-none"
    :class="
      navbarFixed
        ? 'position-sticky blur shadow-blur top-1 z-index-sticky py-2'
        : ''
    "
    flat>
    <v-row class="py-1">
        <v-col
            cols="12"
            sm="6"
            class="d-flex align-center">
            <!--div>
                <v-breadcrumbs class="pb-0 px-0">
                    <v-breadcrumbs-item
                        to="/pages/dashboard/dashboard"
                        active-class="active-breadcrumb"
                        class="opacity-5 text-dark">
                        <v-icon style="margin-bottom:2px">fas fa-home</v-icon>
                    </v-breadcrumbs-item>
                </v-breadcrumbs>

                <h6 class="text-h6 font-weight-bolder text-typo mb-0">
                    {{ $route.name }}
                </h6>
            </div-->
            <!--div
                class="drawer-toggler pa-5 ms-6 cursor-pointer"
                :class="{ active: togglerActive }"
                @click="minifyDrawer"
                v-if="!$vuetify.breakpoint.xs">
                <div class="drawer-toggler-inner">
                    <i class="drawer-toggler-line bg-body"></i>
                    <i class="drawer-toggler-line bg-body"></i>
                    <i class="drawer-toggler-line bg-body"></i>
                </div>
            </div-->
        </v-col>

        <v-col cols="12" sm="6">
            <div class="d-flex align-center justify-end" :class="$vuetify.rtl ? 'text-sm-left' : 'text-sm-right'">
                <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                    font-weight-600
                    text-capitalize
                    drawer-toggler
                    py-3
                    px-0
                    rounded-sm
                    mx-0
                    active
                    btn-dark-toggler-hover
                "
                    v-if="$vuetify.breakpoint.xs"
                    color="transparent"
                    @click="toggleDrawer">
                    <div class="drawer-toggler-inner">
                        <i class="drawer-toggler-line text-body"></i>
                        <i class="drawer-toggler-line text-body"></i>
                        <i class="drawer-toggler-line text-body"></i>
                    </div>
                </v-btn>
                <!--div class="me-4">
                    <v-icon
                        size="16"
                        style="cursor:pointer"
                        class="text-sm"
                        @click="$router.push({name: '마이페이지'})">fa fa-cog</v-icon>
                </div-->
                <!--div class="text-sm me-2">
                    <v-badge
                        dot
                        :value="noticeNew"
                        color="red"
                        overlap
                        class="pe-2">
                        <v-icon
                            size="16"
                            style="cursor:pointer"
                            @click="redirectToUrl('/pages/dashboard/customer-services-temp?screen=notice')">mdi-bullhorn</v-icon>
                    </v-badge>
                </div-->

                <div
                    class="me-4 text-sm d-flex justify-start align-center font-weight-600 text-capitalize"
                    style="cursor:pointer"
                    @click="logout">
                    <v-icon size="16" class="me-2">fa fa-sign-out-alt</v-icon>
                    <span class="d-sm-inline font-weight-bold d-none text-body">로그아웃</span>
                </div>
            </div>
            <div class="text-right text-sm text-body px-6" v-if="$vuetify.breakpoint.xs">
                {{seller.companyName}}({{ seller.phone }})님, 환영합니다!
            </div>
        </v-col>
    </v-row>
</v-app-bar>
</template>

<script>
import {
    query
} from 'quill';
import '../plugins/firebase';
import firebase from 'firebase/compat/app';
export default {
    name: "app-bar",
    props: {
        background: String,
        hasBg: Boolean,
        linkColor: String,
        toggleActive: Boolean,
        navbarFixed: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            drawer: false,
            togglerActive: false,
            dropdown: [{
                    avatar: require("@/assets/img/team-2.jpg"),
                    title: '<span class="font-weight-bold">New message</span> from Laur</h6>',
                    time: "13 minutes ago",
                },
                {
                    image: require("@/assets/img/small-logos/logo-spotify.svg"),
                    title: '<span class="font-weight-bold">New album</span> by Travis Scott',
                    time: "1 day",
                },
                {
                    icon: require("@/assets/img/icons/card.svg"),
                    title: "Payment successfully completed",
                    time: "2 days",
                },
            ],
            seller: {},
        };
    },
    async mounted() {
        let user = this.$auth.currentUser();
        try {
            this.seller = await this.$database.loadSellerUser(user.uid);
        }
        catch(e) {
            console.log(e);
        }
    },
    destroyed() {
    },
    methods: {
        redirectToUrl(url) {
            const currLocation = window.location;
            let currUrl = '';
            if (currLocation.port != '80') {
                currUrl = `${currLocation.protocol}//${currLocation.hostname}:${currLocation.port}${currLocation.pathname}${currLocation.search}`;
            } else {
                currUrl = `${currLocation.protocol}//${currLocation.hostname}${currLocation.pathname}${currLocation.search}`;
            }
            window.location.href = currUrl + '#' + url;
        },
        toggleDrawer() {
            this.togglerActive = !this.togglerActive;
            this.$emit("drawer-toggle", true);
        },
        minifyDrawer() {
            this.togglerActive = !this.togglerActive;
            this.mini = !this.mini;
            const body = document.getElementsByTagName("body")[0];
            if (body.classList.contains("drawer-mini")) {
                body.classList.remove("drawer-mini");
            } else {
                body.classList.add("drawer-mini");
            }
        },
        async logout() {
            await this.$auth.logout();
            this.$router.replace({name: '로그인'});
        }
    },
    watch: {
        toggleActive(val) {
            this.togglerActive = val;
        },
    },
};
</script>
