import Vue from "vue";
import axios from "axios";

export default {
};

export const gorela = {
  install(Vue) {
    Vue.prototype.$gorela = {
      companyId: "doctor365",
      //storeId: "doctor365p",
      storeId: "",
      //authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZVc2VySWQiOjE0NCwiYXBpS2V5SWQiOjEwMDEsInJvbGUiOiJvcmRlckFnZW5jeU1hbmFnZXIiLCJvcmRlckFnZW5jeUlkcyI6WyJkb2N0b3IzNjUiXSwiaWF0IjoxNjYwMDMwNjA4LCJleHAiOjE4MTc3MTA2MDh9.5N2igxPCgOXUTZFRdSpuQ5rX6qHvGMr7gU4YfT1bkMs", - 스테이징
      authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZVc2VySWQiOjg3LCJhcGlLZXlJZCI6MTYyLCJyb2xlIjoib3JkZXJBZ2VuY3lNYW5hZ2VyIiwib3JkZXJBZ2VuY3lJZHMiOlsiZG9jdG9yMzY1Il0sImlhdCI6MTY2ODU4NjY5MSwiZXhwIjoxODI2MjY2NjkxfQ.7AxyQNyW88-ogsrc2xHl33i3kUhHdDHlW6wrxik-d5U",
      requestDeliveryPossible: async function (item, price) {
        console.log('companyId', this.companyId);
        console.log('storeId', this.storeId);
        let result = {};
        // var url = 'https://staging-api-interlocker.gorelas.com/api/delivery-possible';
        var url = 'https://api-interlocker.gorelas.com/api/delivery-possible';
        var data = {
          "orderAgencyId": this.companyId,
          "orderAgencyStoreId": this.storeId,
          "dropJibunAddress": item.address,
          "dropAddressDetail": item.addressDetail,
          "pickupWishAt": (new Date).getTime(),
          "totalPayPrice": price,
          "actualPayPrice": price,
          "isUntact": true,
        };
        if ((item.doroAddress != null) && (item.doroAddress != undefined)) {
          if (item.doroAddress.length > 0) {
            data['dropRoadAddress'] = item.doroAddress;
          }
        }
        //console.log('data', data);
        await axios.post(url, JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            "Authorization": this.authorization
          },
        })
          .then(function (response) {
            result = response.data.data;
          })
          .catch(function (error) {
            console.log(error);
            result = {
              reason: error.response.data.error.errorCode
            }
          });
        return result;
      },
      requestOrder: async function (pharmacy, item, orderId) {
        console.log('companyId', this.companyId);
        console.log('storeId', this.storeId);
        let result = {};
        var url = 'https://api-interlocker.gorelas.com/api/orders';
        var data = {
          "orderType": "FOR_DELIVERY",
          "orderAgencyId": this.companyId,
          "orderAgencyOrderId": orderId,
          "orderAgencyStoreId": this.storeId,
          "totalPayPrice": item.paymentAmount,
          "actualPayPrice": item.paymentAmount,
          "prepaidPrice": item.paymentAmount,
          "deferredPrice": 0,
          "paymentCashPrice": 0,
          "paymentCardPrice": 0,
          "orderProducts": [{
            "type": "PHARMACY",
            "name": "의약품",
            "totalPrice": item.paymentAmount,
            "unitPrice": item.paymentAmount,
            "quantity": 1,
          }],
          "ordererPhone": pharmacy.accountUserPhone,
          "receiverPhone": item.patientPhone,
          "ordererName": item.pharmacyName,
          "receiverName": item.patientName,
          "ordererOrderMemo": item.ordererOrderMemo, // model에 추가하고 앱에서 입력받도록 해야함
          "pickupWishAt": (new Date()).getTime(),
          "dropJibunAddress": item.address,
          "dropRoadAddress": item.doroAddress,
          "dropAddressDetail": item.addressDetail,
          "isUntact": true,
        };
        if ((item.addressAdditionalInfo != undefined) && (item.addressAdditionalInfo != null)) {
          if (item.addressAdditionalInfo.length > 0) {
            data.ordererOrderMemo = item.addressAdditionalInfo;
          }
        }
        await axios.post(url, JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            "Authorization": this.authorization
          },
        })
          .then(function (response) {
            result = response.data.data;
          })
          .catch(function (error) {
            console.log(error);
          });
        return result;
      },
      cancelOrder: async function (orderId, cancelType, cancelReason) {
        let result = {};
        var url = 'https://api-interlocker.gorelas.com/api/orders/' + orderId + '/status/cancel';
        var data = {
          "time": (new Date()).getTime(),
          "cancelType": cancelType,
          "cancelReason": cancelReason,
        };
        await axios.put(url, JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            "Authorization": this.authorization
          },
        })
          .then(function (response) {
            result = response.data.data;
            console.log(result);
          })
          .catch(function (error) {
            console.log(error);
          });
        return result;
      }
    }
  }
};

Vue.use(gorela);