export default {
  apiKey: "AIzaSyDDo9VbbqdB1B3LVJN7qa3JMgRxXQwSKGs",
  authDomain: "doctor365-9a68d.firebaseapp.com",
  databaseURL: "https://doctor365-9a68d-default-rtdb.firebaseio.com",
  projectId: "doctor365-9a68d",
  storageBucket: "doctor365-9a68d.appspot.com",
  messagingSenderId: "41805375326",
  appId: "1:41805375326:web:ef52b93e79664fadcd91cf",
  measurementId: "G-VYMLCEEB64"
};
