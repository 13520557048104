import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";
import '../plugins/firebase';
import firebase from 'firebase/compat/app';

// Dashboard pages
const SignIn = () => import("../views/Auth/SignIn.vue");
const SignUp = () => import("../views/Auth/SignUp.vue");
const FindPw = () => import("../views/Auth/FindPw.vue");
const Dashboard = () => import("../views/Dashboard/Dashboard.vue");
const ChangePw = () => import("../views/Dashboard/ChangePw.vue");
const Quotations = () => import("../views/Dashboard/Quotations.vue");
const Orders = () => import("../views/Dashboard/Orders.vue");
const Order = () => import("../views/Dashboard/Order.vue");
const Deliverys = () => import("../views/Dashboard/Deliverys.vue");
const Delivery = () => import("../views/Dashboard/Delivery.vue");
const QuotationRequests = () => import("../views/Dashboard/QuotationRequests.vue");
const QuotationRequest = () => import("../views/Dashboard/QuotationRequest.vue");
const Quotation = () => import("../views/Dashboard/Quotation.vue");
const QuotationCustomerServices = () => import("../views/Dashboard/QuotationCustomerServices.vue");
const QuotationCustomerService = () => import("../views/Dashboard/QuotationCustomerService.vue");
const SellerNotice = () => import("../views/Dashboard/SellerNotice.vue");
const MyInfo = () => import("../views/Dashboard/MyInfo.vue");
const Settings = () => import("../views/Dashboard/Settings.vue");
const QuotationItemsPage = () => import("../views/Dashboard/QuotationItemsPage.vue");
const Settlement = () => import("../views/Dashboard/Settlement.vue");
const CustomerServices = () => import("../views/Dashboard/CustomerServices.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: "Auth",
    redirect: "/pages/signin",
    component: AuthBasicLayout,
    meta: {
      requireAuth: false
    },
    children: [
      {
        path: "/pages/signin",
        name: "로그인",
        component: SignIn,
        meta: {
          requireAuth: false
        },
      },
      {
        path: "/pages/signup",
        name: "회원가입",
        component: SignUp,
        props: true,
        meta: {
          requireAuth: false
        },
      },
      {
        path: "/pages/findpw",
        name: "비밀번호 찾기",
        component: FindPw,
        meta: {
          requireAuth: false
        },
      },
    ],
  },
  {
    path: '/',
    name: "Dashboard",
    redirect: "/pages/dashboard/quotation-requests",
    component: DashboardLayout,
    meta: {
      requireAuth: true
    },
    children: [
      {
        path: "/pages/dashboard/quotation-requests",
        name: "견적요청보기",
        component: QuotationRequests,
        meta: {
          requireAuth: true
        },
      },
      {
        path: "/pages/dashboard/quotation-request",
        name: "견적요청정보",
        component: QuotationRequest,
        meta: {
          requireAuth: true
        },
        props: true,
      },
      {
        path: "/pages/dashboard/quotation",
        name: "견적정보",
        component: Quotation,
        meta: {
          requireAuth: true
        },
        props: true,
      },
      {
        path: "/pages/dashboard/quotations",
        name: "견적관리",
        component: Quotations,
        meta: {
          requireAuth: true
        },
      },
      {
        path: "/pages/dashboard/orders",
        name: "발주관리",
        component: Orders,
        meta: {
          requireAuth: true
        },
      },
      {
        path: "/pages/dashboard/order",
        name: "발주정보",
        component: Order,
        meta: {
          requireAuth: true,
        },
        props: true,
      },
      {
        path: "/pages/dashboard/deliverys",
        name: "납품관리",
        component: Deliverys,
        meta: {
          requireAuth: true
        },
      },
      {
        path: "/pages/dashboard/delivery",
        name: "납품정보",
        component: Delivery,
        meta: {
          requireAuth: true
        },
        props: true,
      },
      {
        path: "/pages/dashboard/settlement",
        name: "정산관리",
        component: Settlement,
        meta: {
          requireAuth: true
        },
      },
      {
        path: "/pages/dashboard/quotation-customer-services",
        name: "상담관리",
        component: QuotationCustomerServices,
        meta: {
          requireAuth: true
        },
        props: true,
      },
      {
        path: "/pages/dashboard/quotation-customer-service",
        name: "상담정보",
        component: QuotationCustomerService,
        meta: {
          requireAuth: true
        },
        props: true,
      },
      {
        path: "/pages/dashboard/quotation-items",
        name: "품목관리",
        component: QuotationItemsPage,
        meta: {
          requireAuth: true
        },
        props: true,
      },
      {
        path: "/pages/dashboard/seller-notice",
        name: "공지사항",
        component: SellerNotice,
        meta: {
          requireAuth: true
        },
        props: true,
      },
      {
        path: "/pages/dashboard/customer-services",
        name: "고객센터",
        component: CustomerServices,
        meta: {
          requireAuth: true,
        },
        props: true,
      },
      {
        path: "/pages/dashboard/settings",
        name: "기타 설정",
        component: Settings,
        meta: {
          requireAuth: true
        },
        props: true,
      },
      {
        path: "/pages/dashboard/my-info",
        name: "내 정보",
        component: MyInfo,
        meta: {
          requireAuth: true
        },
        props: true,
      },
      {
        path: "/pages/dashboard/changepw",
        name: "비밀번호 변경",
        component: ChangePw,
        meta: {
          requireAuth: true
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  let nextPath = null;
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (firebase.auth().currentUser == null) {
      nextPath = '/pages/signin';
    }
  }

  if (nextPath == null) {
    next();
  }
  else {
    next({ path: nextPath });
  }
});

export default router;
